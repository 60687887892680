unit Module.MainForm;

interface

uses
  SysUtils, Classes, JS, Web, WEBLIB.Controls, WEBLib.Modules, WEBLib.Actions;

type
  TdmMainForm = class(TDataModule)
    alForm: TElementActionList;
    procedure btnLoadClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure edtFileChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure selFormChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoTextInputChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoSaveData(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure cbUntranslatedChanged(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure DoPrimaryChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
  private
    // first language is primary.
    // Objects contain the TJSObject;
    FLanguages : TStrings;
    FData : TJSObject;
    procedure ShowHeaders(aLanguages: TStrings);
    procedure FillFormsCombo(aJSON: TJSObject);
    function GetPrimaryLanguage: String;
    procedure ShowStrings(AllStrings : Boolean);
    function ShowFormStrings(aFormName: string; aFormStrings: TJSObject; AllStrings : Boolean) : string;
    procedure SaveStrings;
    function GetTranslateAllStrings: Boolean;
    function GetHaveStrings: Boolean;
  public
    { Public declarations }
    procedure LoadJSONFile;
    procedure ExtractLanguages(aJSON : TJSObject; aList : TStrings);
    Procedure FillLanguageCombo(aLanguages : TStrings);
    procedure ShowJSON(aJSON : TJSObject);
    Property HaveStrings : Boolean Read GetHaveStrings;
    property PrimaryLanguage : String Read GetPrimaryLanguage;
    Property TranslateAllStrings : Boolean Read GetTranslateAllStrings;
  protected procedure LoadDFMValues; override; end;

var
  dmMainForm: TdmMainForm;

implementation

uses Units.ActionUtils, types, Units.TextEncode;


{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

Function LangDisplayName(aName : string) : String;


begin
  Case aName of
    'nl' : Result:='Nederlands';
    'fr' : Result:='Frans';
    'en' : Result:='Engels';
  else
    Result:='Unknown language : '+aName;
  End;
end;

Procedure TdmMainForm.FillLanguageCombo(aLanguages : TStrings);

Var
  aName,aDisplay : String;
  Sel : String;

begin
  Sel:='';
  For aName in aLanguages do
    begin
    aDisplay:=LangDisplayName(aName);
    Sel:=Sel+Format('<option value="%s">%s</option>',[aName,aDisplay]);
    end;
  alForm['selLanguage'].ElementHandle.innerHTML:=Sel;
end;

function TdmMainForm.GetHaveStrings: Boolean;
begin
  Result:=Assigned(FData);
end;

function TdmMainForm.GetPrimaryLanguage: String;
begin
  Result:=FLanguages[0];
end;

function TdmMainForm.GetTranslateAllStrings: Boolean;
begin
  Result:= Not alForm['cbUntranslatedOnly'].Checked
end;

procedure TdmMainForm.cbUntranslatedChanged(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if HaveStrings then
    ShowStrings(TranslateAllStrings);
end;

procedure TdmMainForm.DoPrimaryChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

var
  lang : String;
  Idx : Integer;

begin
  if HaveStrings then
    begin
    Lang:=alForm['selLanguage'].Value;
    Idx:=FLanguages.IndexOf(Lang);
    if Idx>0 then
      FLanguages.Move(Idx,0);
    ShowHeaders(FLanguages);
    ShowStrings(TranslateAllStrings);
    end;
end;

procedure TdmMainForm.DoSaveData(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if HaveStrings then
    SaveStrings;
end;

procedure TdmMainForm.DoTextInputChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

Var
  tr,td : TJSHTMLElement;
  Idx: Integer;
  aLang, aForm, aName : String;
  aLangForms,aLangForm : TJSObject;

begin
  //
  td:=TJSHTMLElement(Element.Element.parentElement);
  tr:=TJSHTMLElement(td.parentElement);
  aLang:=String(td.Dataset['lang']);
  aName:=String(tr.Dataset['name']);
  aForm:=String(tr.Dataset['form']);
  Idx:=FLanguages.IndexOf(aLang);
  aLangForms:=TJSObject(Flanguages.Objects[Idx]);
  aLangForm:=TJSObject(aLangForms[aForm]);
  aLangForm[aName]:=TJSHTMLInputElement(Element.Element).Value;
end;

procedure TdmMainForm.edtFileChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
   LoadJSONFile;
end;

procedure TdmMainForm.ExtractLanguages(aJSON : TJSObject; aList : TStrings);

Var
  aName : String;
  idx,idxEn,idxNL : Integer;

begin
  idxEn:=-1;
  idxNL:=-1;
  For aName in TJSObject.getOwnPropertyNames(aJSON) do
    begin
    idx:=aList.AddObject(aName,TObject(aJSON[aName]));
    if aName='nl' then
      idxNL:=idx
    else if aName='en' then
      idxEN:=idx
    end;
  if (idxEN>=0) then
    begin
    if idxEN>0 then
      aList.Move(idxEn,0)
    end
  else if idxNL>=0 then
    aList.Move(idxNL,0);
end;

procedure TdmMainForm.ShowHeaders(aLanguages : TStrings);

Var
  I : Integer;
  aName,aDisplay,aHTML : String;

begin
  aHTML:='';
  For I:=0 to aLanguages.Count-1 do
    begin
    aName:=aLanguages[I];
    aDisplay:=LangDisplayName(aName);
    aHTML:=aHTML+Format('<th data-lang="%s">%s</th>',[aName,aDisplay]);
    end;
  alForm['tblHead'].ElementHandle.innerHTML:='<tr>'+aHTML+'</tr>';
end;

procedure TdmMainForm.FillFormsCombo(aJSON : TJSObject);

Var
  aHTML,aName : String;

begin
  aHTML:='<option value="*">Alle forms</option>';
  For aName in TJSObject.getOwnPropertyNames(aJSON) do
    begin
    if (Copy(aName,1,2)<>'__') then
      aHTML:=aHTML+Format('<option value="%s">%s</option>',[aName,aName]);
    end;
  alForm['selForm'].ElementHandle.innerHTML:=aHTML;
end;



procedure TdmMainForm.SaveStrings;

Var
  Link : TJSHTMLElement;
  aBlob : TJSBlob;

begin
  link:=TJSHTMLElement(document.createElement('a'));
  link['download']:='lang.json';
  aBlob:=TJSBlob.New([TJSJSON.StringIfy(FData)],New(['type','application/json']));
  link['href']:=TJSURL.createObjectURL(aBlob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
end;

procedure TdmMainForm.ShowStrings(AllStrings : Boolean);

Var
  FormList : TStringDynArray;
  aHTML,selForm : String;
  aForm : TJSObject;
  JSStrings : TJSObject;


begin
  JSStrings:=TJSObject(FLanguages.Objects[0]);
  selForm:=alForm['selForm'].Value;
  if selForm='*' then
    begin
    FormList:=TJSObject.GetOwnPropertyNames(JSStrings);
    alForm['lblFormName'].Value:='All forms';
    end
  else
    begin
    FormList:=[selForm];
    alForm['lblFormName'].Value:=selForm;
    end;
  aHTML:='';
  for SelForm in FormList do
    if (Copy(selForm,1,2)<>'__') then
      begin
      aForm:=TJSObject(JSStrings[selForm]);
      // Exclude specials
      aHTML:=aHTML+ShowFormStrings(selForm,aForm,AllStrings);
      end;
  alForm['tblBody'].ElementHandle.InnerHTML:=aHTML;
  alForm['allInputs'].Bind;
end;

Function TdmMainForm.ShowFormStrings(aFormName : string; aFormStrings : TJSObject;AllStrings : Boolean) : string;

  Function MustTranslate(aTerm: String; aForms: Array of TJSObject) : Boolean;
  // We must translate if there are 2 the same or there is a missing string

  Var
    I : Integer;
    S : String;
    aValue : JSValue;

  begin
    Result:=False;
    S:=String(aForms[0][aTerm]);
    I:=1;
    While (Not Result) and (I<Length(aForms)) do
      begin
      aValue:=aForms[I][aTerm];
      Result:=Not IsString(aValue) or (S=String(aValue));
      Inc(I);
      end;
  end;


Var
  aTerms : TStringDynArray;
  LangObj : TJSObject;
  aForms : Array of TJSObject;
  aPlaceHolder,aValue,aRow,aTerm : String;
  I : Integer;

begin
  aTerms:=TJSObject.GetOwnPropertyNames(aFormStrings);
  SetLength(aForms,FLanguages.Count);
  aForms[0]:=aFormStrings;
  // Collect objects with strings of other languages
  For I:=1 to FLanguages.Count-1 do
    begin
    LangObj:=TJSObject(FLanguages.Objects[I]);
    // If the form does not exist for this language, create it.
    if Not isObject(LangObj[aFormName]) then
      LangObj[aFormName]:=TJSObject.New;
    aForms[i]:=TJSObject(LangObj[aFormName]);
    end;
  Result:='';
  For aTerm in aTerms do
    if AllStrings or MustTranslate(aTerm,aForms) then
    begin
    aRow:=Format('<tr data-form="%s" data-name="%s">',[aFormName,aTerm]);
    For I:=0 to Length(aForms)-1 do
      begin
      aPlaceHolder:='vertaling in '+LangDisplayName(FLanguages[i]);
      aValue:=String(aForms[i][aTerm]);
      aRow:=aRow+Format('<td data-lang="%s"><input class="form-control form-control-sm" value="%s" placeholder="%s"/></td>',[FLanguages[i],aValue,aPlaceHolder]);
      end;
    Result:=Result+aRow+'</tr>';
    end;

end;

procedure TdmMainForm.ShowJSON(aJSON : TJSObject);

begin
  FreeAndNil(FLanguages);
  FData:=aJSON;
  FLanguages:=TStringList.Create;
  ExtractLanguages(aJSON,FLanguages);
  FillLanguageCombo(FLanguages);
  FillFormsCombo(TJSObject(FLanguages.Objects[0]));
  alForm['divLoad'].AddClass('d-none');
  alForm['divLoaded'].RemoveClass('d-none');
  ShowHeaders(FLanguages);
  ShowStrings(TranslateAllStrings);
end;


procedure TdmMainForm.LoadJSONFile;

  Function LoadFromFile(aData : JSValue) : JSValue;

  var
    S : String;
    utf8Decoder : TJSTextDecoder;
    J : TJSObject;

  begin
    Result:=False;
    utf8Decoder:=TJSTextDecoder.New;
    S:=utf8Decoder.decode(TJSUInt8Array.New(TJSArrayBuffer(aData)));
    J:=TJSJSON.parseObject(S);
    ShowJSON(J);
  end;

Var
  edtJSONFile : TJSHTMLInputElement;
  aFileName: String;

begin
  edtJSONFile:=TJSHTMLInputElement(alForm['edtFile'].ElementHandle);
  if (edtJSONFile.files.length=1) then
    begin
    aFileName:=ExtractFileName(edtJSONFile.files[0].Name);
    edtJSONFile.files[0].arraybuffer._then(@loadFromFile);
    Document.Title:='Translating '+aFileName;
    // Todo: Display filename somewhere
    end;
end;


procedure TdmMainForm.selFormChange(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

begin
  if HaveStrings then
    ShowStrings(TranslateAllStrings);
end;

procedure TdmMainForm.btnLoadClick(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter);

Var
  el : TJSHTMLElement;

begin
  el:=TJSHTMLElement(alForm['edtFile'].ElementHandle);
  el.Click;
end;

procedure TdmMainForm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  alForm := TElementActionList.Create(Self);

  alForm.BeforeLoadDFMValues;
  try
    Name := 'dmMainForm';
    Height := 185;
    Width := 255;
    alForm.SetParentComponent(Self);
    alForm.Name := 'alForm';
    alForm.Actions.Clear;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'btnLoad';
      Name := 'btnLoad';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'btnLoadClick');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'btnSave';
      Name := 'btnSave';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoSaveData');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'selForm';
      Name := 'selForm';
      SetEvent(Self, 'OnExecute', 'selFormChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'cbUntranslatedOnly';
      Name := 'cbUntranslatedOnly';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'cbUntranslatedChanged');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'edtFile';
      Name := 'edtFile';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'edtFileChange');
    end;
    with alForm.Actions.Add do
    begin
      ID := 'tblHead';
      Name := 'tblHead';
    end;
    with alForm.Actions.Add do
    begin
      ID := 'tblBody';
      Name := 'tblBody';
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := 'selLanguage';
      Name := 'selLanguage';
      PreventDefault := False;
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoPrimaryChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heChange;
      ID := '';
      Name := 'allInputs';
      PreventDefault := False;
      Selector := '#tblBody input';
      StopPropagation := False;
      SetEvent(Self, 'OnExecute', 'DoTextInputChange');
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'divLoad';
      Name := 'divLoad';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'divLoaded';
      Name := 'divLoaded';
      PreventDefault := False;
      StopPropagation := False;
    end;
    with alForm.Actions.Add do
    begin
      Event := heNone;
      ID := 'lblFormName';
      Name := 'lblFormName';
      PreventDefault := False;
      StopPropagation := False;
    end;
    alForm.Left := 56;
    alForm.Top := 32;
  finally
    alForm.AfterLoadDFMValues;
  end;
end;

end.
