unit Units.HTMLUtils;

interface

uses JS, web, sysutils, dateutils, types;

// Type
//   TStringDynArray = Array of String;

procedure AddClass(EM: TJSHTMLElement; Const aClass: String); overload;
procedure AddClass(aList: TJSNodeList; Const aClass: String); overload;
procedure RemoveClass(EM: TJSHTMLElement; Const aClass: String); overload;
procedure RemoveClass(aList: TJSNodeList; Const aClass: String); overload;
procedure AddRemoveClass(EM: TJSHTMLElement; Const aAddClass, aRemoveClass: String); overload;
procedure AddRemoveClass(aList: TJSNodeList; Const aAddClass, aRemoveClass: String); overload;
function HasClass(aElement: TJSHTMLElement;  const aClass: String): Boolean;

Function UTCToLocalTime(aDateTime: TDateTime): TDateTime;
Function LocalTimeToUTC(aDateTime: TDateTime): TDateTime;

Function ExtractDate(S: String): TDateTime;
Function ExtractTime(S: String): TDateTime;

Function FormatHTMLDate(aDateTime : TDateTime; ZeroAsEmpty : Boolean = True) : string;

function getRadioGroupValue(const groupName: String): string;
procedure setRadiogroupSelectedElement(const groupName, value: string);

Function GetInputValue(el: TJSHTMLElement): string;
Procedure SetInputValue(el: TJSHTMLElement; aValue: String);

Function HtmlIze(aString : String) : String;

Function AbsoluteURL(aBaseURL,aURL : String) : string;

procedure DisplayError(const ident: string; errorClass : string = ''; aMessage: string = ''; UseCol : Boolean = True);
procedure DisplayRGError(const aName: string; errorClass : string = ''; aMessage: string = ''; UseCol : Boolean = True);
procedure HideError(const ident: string; errorClasses: array of string; UseCol : Boolean = True);
procedure HideRGError(const aName: string; errorClasses: array of string; UseCol : Boolean = True);

procedure ClearValidStatus(const ident: string; ByName : Boolean = False);
procedure ShowValidStatus(const ident: string; IsValid : Boolean; ByName : Boolean = False);

Type
  TJSHTMLElementHelper = Class helper for TJSHTMLElement
  private
    Function GetData(aName: String): String;
    Procedure SetData(aName, aValue: String);
    function GetInputValue: String;
    procedure SetInputValue(const Value: String);
  Public
    procedure AddClass(Const aClass: String); overload;
    procedure RemoveClass(Const aClass: String); overload;
    procedure AddRemoveClass(Const aAddClass, aRemoveClass: String); overload;
    function HasClass(const aClass: String): Boolean;
    Property InputValue: String Read GetInputValue Write SetInputValue;
    Property Data[Index: String]: String Read GetData Write SetData;
  end;

Function JSValueToInt(a: JSValue) : Integer;

implementation

uses libJQuery, StrUtils;

Function JSValueToInt(a: JSValue) : Integer;

begin
  if isNumber(a) then
    Result:=Trunc(Double(a))
  else if isString(a) then
    Result:=StrToIntDef(String(a),0)
  else
    Result:=0;
end;


Function FormatHTMLDate(aDateTime : TDateTime; ZeroAsEmpty : Boolean = True) : string;

begin
  if (Trunc(aDateTime)=0) and (ZeroAsEmpty) then
    Result:=''
  else
    Result:=FormatDateTime('yyyy"-"mm"-"dd',aDateTime);
end;

Function ExtractDate(S: String): TDateTime;

Var
  Y, M, D: Word;

begin
  Y:=StrToIntDef(Copy(S, 1, 4), 0);
  M:=StrToIntDef(Copy(S, 6, 2), 0);
  D:=StrToIntDef(Copy(S, 9, 2), 0);
  if (Y<>0) and (M>0) and (M<13) and (D>0) and (D<32) then
    Result:=EncodeDate(Y, M, D)
  else
    Result:=0;
end;

Function ExtractTime(S: String): TDateTime;

Var
  H, M, Sec: Word;

begin
  H:=StrToIntDef(Copy(S, 1, 2), 0);
  M:=StrToIntDef(Copy(S, 4, 2), 0);
  if Length(S)>6 then
    Sec:=StrToIntDef(Copy(S, 7, 2), 0)
  else
    Sec:=0;
  if (H>=0) and (H<25) and (M>=0) and (M<60) and (Sec>=0) and (Sec<60) then
    Result:=EncodeTime(H, M, Sec, 0)
  else
    Result:=0;
end;

Function UTCToLocalTime(aDateTime: TDateTime): TDateTime;

Var
  Y, Mo, D, H, Mi, S, MS: Word;
  JD: TJSDate;

begin
  DecodeDateTime(aDateTime, Y, Mo, D, H, Mi, S, MS); // will be In UTC
  // Returns local time
  JD:=TJSDate.New(TJSDate.UTC(Y, Mo-1, D, H, Mi, S));
  Result:=JSDateToDateTime(JD);
end;

Function LocalTimeToUTC(aDateTime: TDateTime): TDateTime;

Var
  JD: TJSDate;

begin
  // Local time
  JD:=DateTimeToJSDate(aDateTime);
  Result:=EncodeDateTime(JD.UTCFullYear, JD.UTCMonth+1, JD.UTCDate, JD.UTCHours, JD.UTCMinutes, JD.UTCSeconds, 0);
end;

procedure RemoveClass(EM: TJSHTMLElement; Const aClass: String);

begin
  AddRemoveClass(EM, '', aClass);
end;

procedure RemoveClass(aList: TJSNodeList; Const aClass: String);

Var
  I: Integer;

begin
  For I:=0 to aList.Length-1 do
    if (aList[I] is TJSHTMLElement) then
      AddRemoveClass(TJSHTMLElement(aList[I]), '', aClass);
end;

procedure AddClass(aList: TJSNodeList; Const aClass: String);

Var
  I: Integer;

begin
  For I:=0 to aList.Length-1 do
    if (aList[I] is TJSHTMLElement) then
      AddRemoveClass(TJSHTMLElement(aList[I]), aClass, '');
end;

procedure AddClass(EM: TJSHTMLElement; Const aClass: String);

begin
  AddRemoveClass(EM, aClass, '');
end;

procedure AddRemoveClass(aList: TJSNodeList; Const aAddClass, aRemoveClass: String); overload;

Var
  I: Integer;

begin
  For I:=0 to aList.Length-1 do
    if (aList[I] is TJSHTMLElement) then
      AddRemoveClass(TJSHTMLElement(aList[I]), aAddClass, aRemoveClass);
end;

procedure AddRemoveClass(EM: TJSHTMLElement; Const aAddClass, aRemoveClass: String);

Var
  S: String;
  List: TStringDynArray;
  idx: Integer;

begin
  if Not Assigned(EM) then
    Exit;
  List:=TJSString(EM.ClassName).split(' ');
  if (aRemoveClass<>'') then
  begin
    idx:=TJSArray(List).indexOf(aRemoveClass);
    if idx<>-1 then
      Delete(List, idx, 1);
  end;
  if (aAddClass<>'') then
  begin
    idx:=TJSArray(List).indexOf(aAddClass);
    if idx=-1 then
      TJSArray(List).Push(aAddClass);
  end;
  S:=TJSArray(List).join(' ');
  EM.ClassName:=S;
end;

function HasClass(aElement: TJSHTMLElement; const aClass: String): Boolean;
var
  List: TStringDynArray;
  aIndex: Integer;
begin
  if Not Assigned(aElement) then
    Exit(False);
  if aClass = '' then
    Exit(False);

  List := TJSString(aElement.ClassName).split(' ');
  aIndex := TJSArray(List).indexOf(aClass);
  Result := (aIndex <> -1);
end;


function getRadioGroupValue(const groupName: String): string;
begin
  Result := string(jQuery('input[name="' + groupName + '"]:checked').val());
  if Result = 'undefined' then
    Result := '';
end;
procedure setRadiogroupSelectedElement(const groupName, value: string);
begin
  jQuery('input[name="' + groupName + '"]').prop('checked', false);
  jQuery('input[name="' + groupName + '"][value="' + value + '"]').prop('checked', true);
end;

Function GetInputValue(el: TJSHTMLElement): string;

Var
  inp: TJSHTMLInputElement absolute el;
  sel: TJSHTMLSelectElement absolute el;
  are: TJSHTMLTextAreaElement absolute el;
  S: String;

begin
  if el is TJSHTMLInputElement then
    S:=inp.Value
  else if el is TJSHTMLSelectElement then
    S:=sel.Value
  else if el is TJSHTMLTextAreaElement then
    S:=are.Value
  else if el=nil then
    begin
    S:='';
    end
  else
    S:=el.InnerText;
  Result:=S;
end;

Procedure SetInputValue(el: TJSHTMLElement; aValue: String);

Var
  inp: TJSHTMLInputElement absolute el;
  sel: TJSHTMLSelectElement absolute el;
  are: TJSHTMLTextAreaElement absolute el;

begin
  if el is TJSHTMLInputElement then
    inp.Value:=aValue
  else if el is TJSHTMLSelectElement then
    sel.Value:=aValue
  else if el is TJSHTMLTextAreaElement then
    are.Value:=aValue
  else if SameText(el.tagName,'IMG')  then
    el['src']:=aValue
  else
    el.InnerText:=aValue
end;

{ ---------------------------------------------------------------------
  TJSHTMLElementHelper
  --------------------------------------------------------------------- }

procedure TJSHTMLElementHelper.AddClass(const aClass: String);
begin
  If Assigned(Self) then
    Units.HTMLUtils.AddClass(Self, aClass);
end;

procedure TJSHTMLElementHelper.AddRemoveClass(const aAddClass, aRemoveClass: String);
begin
  If Assigned(Self) then
    Units.HTMLUtils.AddRemoveClass(Self, aAddClass, aRemoveClass);
end;

Function TJSHTMLElementHelper.GetData(aName: String): string;

begin
  if Assigned(Self) and IsString(Self.Dataset[aName]) then
    Result:=String(Self.Dataset[aName])
  else
    Result:=''
end;

function TJSHTMLElementHelper.GetInputValue: String;
begin
  if Assigned(Self) then
    Result:=Units.HTMLUtils.GetInputValue(Self)
  else
    Result:='';
end;

function TJSHTMLElementHelper.HasClass(const aClass: String): Boolean;
begin
  Result := Units.HTMLUtils.HasClass(Self, aClass);
end;

procedure TJSHTMLElementHelper.RemoveClass(const aClass: String);
begin
  If Assigned(Self) then
    Units.HTMLUtils.RemoveClass(Self, aClass);
end;

procedure TJSHTMLElementHelper.SetData(aName, aValue: String);
begin
  if Assigned(Self) then
    Self.Dataset[aName]:=aValue;
end;

procedure TJSHTMLElementHelper.SetInputValue(const Value: String);
begin
  if Assigned(Self) then
    Units.HTMLUtils.SetInputValue(Self, Value)
end;

Function HtmlIze(aString : String) : String;

begin
  Result:=StringReplace(aString,#13#10,'<br>',[rfReplaceAll]);
  Result:=StringReplace(Result,#13,'<br>',[rfReplaceAll]);
  Result:=StringReplace(Result,#10,'<br>',[rfReplaceAll]);
  Result:=StringReplace(Result,'<','&lt;',[rfReplaceAll]);
  Result:=StringReplace(Result,'>','&gt;',[rfReplaceAll]);
end;

Function AbsoluteURL(aBaseURL,aURL : String) : string;

Var
  R : TJSRegexp;

begin
  R:=TJSRegexp.New('^https?://|^/','i');
  if R.Test(aURL) then
    Result:=aURL
  else
    begin
    if (aBaseURL<>'') and (Copy(aBaseURL,Length(aBaseURL),1)<>'/') then
      aBaseURL:=aBaseURL+'/';
    Result:=aBaseURL+aURL;
    end;
end;

procedure ClearValidStatus(const ident: string; ByName : Boolean = False);

Var
  Sel : String;

begin
  if ByName then
    Sel:='input[name="'+Ident+'"]'
  else
    Sel:='#'+Ident;
  jQuery(Sel).removeClass('is-valid is-invalid');
  if ByName then
    begin
    // Needed for radio group or checkbox
    jQuery(Sel).closest('div[class^="col-"]').find('.invalid-feedback').css('display', 'none');
    jQuery(Sel).closest('div[class^="form-group"]').find('.invalid-feedback').css('display', 'none');
    end;
end;

procedure ShowValidStatus(const ident: string; IsValid : Boolean; ByName : Boolean = False);

Var
  Sel : String;
begin
  if ByName then
    Sel:='input[name="'+Ident+'"]'
  else
    Sel:='#'+Ident;
  jQuery(Sel).removeClass('is-valid is-invalid').addClass(IfThen(IsValid,'is-valid','is-invalid'));
  if ByName then
    begin
    // Needed for radio group or checkbox
    jQuery(Sel).closest('div[class^="col-"]').find('.invalid-feedback').css('display', 'block');
    jQuery(Sel).closest('div[class^="form-group"]').find('.invalid-feedback').css('display', 'block');
    end;
end;


procedure DisplayError(const ident: string; errorClass, aMessage: string; UseCol : Boolean = True);
var
  erClass : string;
begin
  if errorClass = '' then
    erClass := 'invalid-feedback'
  else
    erClass := errorClass;
  if UseCol then
    jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).css('display', 'block');
  jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'block');
  if aMessage <> '' then
    begin
    if UseCol then
      jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).text(aMessage);
    jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).text(aMessage);
    end;
  jQuery('li.step.active').addClass('error');
end;

procedure DisplayRGError(const aName: string; errorClass, aMessage: string; UseCol : Boolean = True);
var
  erClass : string;
  aSel : String;
begin
  if errorClass = '' then
    erClass := 'invalid-feedback'
  else
    erClass := errorClass;
  aSel:='input[name="' + aName+'"]';
  if UseCol then
    jQuery(aSel).closest('div[class^="col-"]').find('.' + erClass).css('display', 'block');
  jQuery(aSel).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'block');
  if aMessage <> '' then
    begin
    if UseCol then
      jQuery(aSel).closest('div[class^="col-"]').find('.' + erClass).text(aMessage);
    jQuery(aSel).closest('div[class^="form-group"]').find('.' + erClass).text(aMessage);
    end;
  jQuery('li.step.active').addClass('error');
end;



procedure HideError(const ident: string; errorClasses: array of string; UseCol : Boolean = True);

var
  erClass : string;

begin
  if Length(errorClasses) = 0 then
    errorClasses:= ['invalid-feedback','incorrect-value'];
  for erClass in errorClasses do
    begin
    if UseCol then
      jQuery('#' + ident).closest('div[class^="col-"]').find('.' + erClass).css('display', 'none');
    jQuery('#' + ident).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'none');
    end;
end;

procedure HideRGError(const aName: string; errorClasses: array of string; UseCol : Boolean = True);

var
  aSel,erClass : string;

begin
  if Length(errorClasses) = 0 then
    errorClasses:= ['invalid-feedback','incorrect-value'];
  aSel:='input[name="' + aName+'"]';
  for erClass in errorClasses do
    begin
    if UseCol then
      jQuery(aSel).closest('div[class^="col-"]').find('.' + erClass).css('display', 'none');
    jQuery(aSel).closest('div[class^="form-group"]').find('.' + erClass).css('display', 'none');
    end;
end;


end.
