unit Units.TextEncode;

{$mode objfpc}
{$modeswitch externalclass}

interface

uses js,web,types;


Type
  TJSTextDecoderOptions = class {$IFDEF PAS2JS} external name 'Object' {$ENDIF} (TJSObject)
    fatal : Boolean;
    ignoreBOM : Boolean;
  end;

  TJSTextDecodeOptions = class  {$IFDEF PAS2JS} external name 'Object' {$ENDIF}  (TJSObject)
    stream : Boolean;
  end;

  TJSTextDecoder = class  {$IFDEF PAS2JS} external name 'TextDecoder'  {$ENDIF} (TJSObject)
  Private
    FEncoding : String; external name 'encoding';
    FFatal : Boolean; external name 'fatal';
    FIgnoreBOM : Boolean; external name 'ignoreBOM';
  Public
    Constructor New(utfLabel : String); overload;
    Constructor New(utfLabel : String; Options : TJSTextDecoderOptions); overload;
    Function decode(arr : TJSTypedArray) : String; overload;
    Function decode(arr : TJSArrayBuffer) : String; overload;
    Function decode(arr : TJSTypedArray; opts : TJSTextDecodeOptions) : String; overload;
    Function decode(arr : TJSArrayBuffer; opts : TJSTextDecodeOptions) : String; overload;
    property Encoding : string Read FEncoding;
    Property Fatal : Boolean Read FFatal;
    Property IgnoreBOM : Boolean Read FIgnoreBOM;
  end;

  TJSBlob = Class external name 'Blob' (web.TJSBlob)
    constructor new(aArray : array of JSValue);
    constructor new(aArray : array of JSValue; Options : TJSObject);
  End;

implementation

end.
