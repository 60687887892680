program Translator;

uses
  WEBLib.Forms,
  
Module.MainForm in 'Module.MainForm.pas',
  Units.TextEncode in 'Units.TextEncode.pas',
  Units.ActionUtils in 'Units.ActionUtils.pas',
  Units.HTMLUtils in 'Units.HTMLUtils.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TdmMainForm, dmMainForm);
  Application.Run;
end.
